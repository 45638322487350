export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
export const SET_CHANNEL_INVALID = 'SET_CHANNEL_INVALID';
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const GET_PREV_MESSAGES_START = 'GET_PREV_MESSAGES_START';
export const GET_PREV_MESSAGES_SUCESS = 'GET_PREV_MESSAGES_SUCESS';
export const GET_PREV_MESSAGES_FAIL = 'GET_PREV_MESSAGES_FAIL';
export const SENDING_MESSAGE_FAILED = 'SENDING_MESSAGE_FAILED';
export const SENDING_MESSAGE_SUCCEEDED = 'SENDING_MESSAGE_SUCCEEDED';
export const SENDING_MESSAGE_START = 'SENDING_MESSAGE_START';
export const RESENDING_MESSAGE_START = 'RESENDING_MESSAGE_START';
export const FETCH_PARTICIPANT_LIST = 'FETCH_PARTICIPANT_LIST';
export const FETCH_BANNED_USER_LIST = 'FETCH_BANNED_USER_LIST';
export const FETCH_MUTED_USER_LIST= 'FETCH_MUTED_USER_LIST';

// event handlers
export const ON_MESSAGE_RECEIVED = 'ON_MESSAGE_RECEIVED';
export const ON_MESSAGE_UPDATED = 'ON_MESSAGE_UPDATED';
export const ON_MESSAGE_DELETED = 'ON_MESSAGE_DELETED';
export const ON_MESSAGE_DELETED_BY_REQ_ID = 'ON_MESSAGE_DELETED_BY_REQ_ID';
export const ON_OPERATOR_UPDATED = 'ON_OPERATOR_UPDATED';
export const ON_USER_ENTERED = 'ON_USER_ENTERED';
export const ON_USER_EXITED = 'ON_USER_EXITED';
export const ON_USER_MUTED = 'ON_USER_MUTED';
export const ON_USER_UNMUTED = 'ON_USER_UNMUTED';
export const ON_USER_BANNED = 'ON_USER_BANNED';
export const ON_USER_UNBANNED = 'ON_USER_UNBANNED';
export const ON_CHANNEL_FROZEN = 'ON_CHANNEL_FROZEN';
export const ON_CHANNEL_UNFROZEN = 'ON_CHANNEL_UNFROZEN';
export const ON_CHANNEL_CHANGED = 'ON_CHANNEL_CHANGED';
export const ON_META_DATA_CREATED = 'ON_META_DATA_CREATED';
export const ON_META_DATA_UPDATED = 'ON_META_DATA_UPDATED';
export const ON_META_DATA_DELETED = 'ON_META_DATA_DELETED';
export const ON_META_COUNTERS_CREATED = 'ON_META_COUNTERS_CREATED';
export const ON_META_COUNTERS_UPDATED = 'ON_META_COUNTERS_UPDATED';
export const ON_META_COUNTERS_DELETED = 'ON_META_COUNTERS_DELETED';
export const ON_MENTION_RECEIVED = 'ON_MENTION_RECEIVED';
